import {generateCode} from "../utils";

class Code {
  check() {
    if (localStorage.getItem('code') === null) {
      localStorage.setItem('code', generateCode());
    }
  }
}

export default new Code();
