import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom/cjs/react-router-dom";
import objectFitImages from 'object-fit-images';
import "./styles/globals.scss";
import "./styles/animation.scss";
import AppContextProvider from "./context/app-context";
import App from "./components/app/app";
import ScormProvider from 'react-scorm-provider';

objectFitImages();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScormProvider version="1.2" debug={process.env.NODE_ENV !== 'production'}>
        <AppContextProvider>
            <App />
        </AppContextProvider>
      </ScormProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
