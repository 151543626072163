import React, {useContext} from "react";
import {Switch, Route} from "react-router-dom/cjs/react-router-dom";
import "./app.scss";
import AppContextProvider, {AppContext} from "../../context/app-context";

const Welcome = React.lazy(() => import("../../pages/welcome/welcome"));
const Home = React.lazy(() => import("../../pages/home/home"));
const Summary = React.lazy(() => import("../../pages/summary/summary"));
const Remotely = React.lazy(() => import("../../pages/remotely/remotely"));
const Travel = React.lazy(() => import("../../pages/travel/travel"));
const WorkFromHome = React.lazy(() => import("../../pages/work-from-home/work-from-home"));
const HealthSecurity = React.lazy(() => import("../../pages/health-security/health-security"));

export default function App() {
  const {browser} = useContext(AppContext);

  return (
    <AppContextProvider>
      <div className={`app app-browser--${browser?.id ?? "unknown"}`}>
        <React.Suspense fallback="Loading...">
          <Switch>
            <Route exact path="/" component={Welcome}/>
            <Route exact path="/:lang/remote-working" component={Home}/>
            <Route exact path="/:lang/summary" component={Summary}/>
            <Route exact path="/:lang/remotely" component={Remotely}/>
            <Route exact path="/:lang/travel" component={Travel}/>
            <Route exact path="/:lang/work-from-home" component={WorkFromHome}/>
            <Route exact path="/:lang/your-health-and-security" component={HealthSecurity}/>
          </Switch>
        </React.Suspense>
      </div>
    </AppContextProvider>
  );
}
