export function map(value, x1, y1, x2, y2) {
  return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
}

export const getModifiers = ({name = null, variation = null}) => {
  if(!name) {throw new Error('"name" prop is required')}
  return variation ? (`${name} ${Array.isArray(variation) ? variation.map(item => `${name}--${item}`).join(' ') : `${name}--${variation}`}`) : name;
};

export const generateCode = (length = 5) => {
  let result = '';
  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].join('');
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].join('');
  for ( var i = 0; i < length; i++ ) {
    const obj = i <= -1 || i === length -1 ? alphabet : numbers;
    result += obj.charAt(Math.floor(Math.random() * obj.length));
  }
  return result;
};
