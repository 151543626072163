import React, {useCallback, useState} from "react";
import browserDetect from "browser-detect";
import {withScorm} from 'react-scorm-provider';
import {useHistory} from 'react-router-dom';
import code from "../services/code";

export const AppContext = React.createContext({});

const DOCUMENT_TITLE = 'HSBC Remote Working';

export default withScorm()(function AppContextProvider({children, sco}) {
  const history = useHistory();
  const [pageY, setPageY] = useState(0);
  const [progress, setProgress] = useState(0);
  const [feedbackAnswered, setFeedbackAnswered] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('language'));
  const [docTitle, setDocTitle] = useState(DOCUMENT_TITLE);

  const routeProgress = {
    [`/`]: 0,
    [`/${language}/remote-working`]: 20,
    [`/${language}/remotely`]: 40,
    [`/${language}/travel`]: 60,
    [`/${language}/work-from-home`]: 80,
    [`/${language}/your-health-and-security`]: 90,
    [`/${language}/summary`]: 100,
  };
  const browser = React.useMemo(() => {
    const result = browserDetect();
    const version = parseInt(result.versionNumber);
    return {
      id: `${result.name}-${version}`,
      name: result.name,
      mobile: result.mobile,
      version,
    };
  }, []);

  const setCurrentPageProgress = useCallback((complete = false) => {
    const keys = Object.keys(routeProgress);
    const routeIndex = keys.indexOf(history.location.pathname);

    if(routeProgress[keys[complete ? routeIndex : routeIndex - 1 !== -1 ? routeIndex - 1 : routeIndex]] <= progress) {
      return;
    }
    const _progress = routeIndex - 1 < 0 ?( complete ? routeProgress[`/${language}`] : 0) : routeProgress[keys[complete ? routeIndex : routeIndex -1]];

    if (sco && sco.apiConnected) {
      sco.set('cmi.core.score.raw', _progress)
    }
    setProgress(_progress);
  }, [history.location.pathname, progress, sco]);

  const selectLanguage = useCallback((lang) => {
    localStorage.setItem('language', lang);
    setLanguage(localStorage.getItem('language'))
  }, []);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    document.title = docTitle
  }, [docTitle]);

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 600);
  }, []);

  React.useEffect(() => {
    setCurrentPageProgress(false);
    code.check();
  }, [history.location.pathname]);

  const [redirected, setRedirected] = useState(false);
  const manageRedirect = React.useCallback(() => {
    if (!redirected) {
      const data = sco.get("cmi.suspend_data");
      let suspendData = {};
      let lastVisitedUrl = '';

      try {
        if (!data || data.length === 0) {
          return;
        }
        suspendData = JSON.parse(data);
        lastVisitedUrl = Object.keys(suspendData).pop();
        if (lastVisitedUrl !== '/') {
          history.push(lastVisitedUrl);
          setProgress(Number(sco.get("cmi.core.score.raw")));
        }
      } catch (e) {
        console.error(e);
      }

      setRedirected(true);
    }
  }, [history, sco, redirected, setProgress]);

  React.useEffect(() => {
    if (sco && sco.apiConnected) {
      manageRedirect();
    } else {
      console.log('sco not connected');
    }
  }, [manageRedirect, sco, progress]);

  return (
    <AppContext.Provider value={{browser, progress, sco, pageY, setPageY, setProgress, setCurrentPageProgress, feedbackAnswered, setFeedbackAnswered, selectLanguage, language, isMobile, setDocTitle}}>
      {children}
    </AppContext.Provider>
  );
})
